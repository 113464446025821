<template>
  <UITableResponsive :items="items" :headers="['Promotie', 'Korting', 'Klant', 'Opmerking', 'Tijd', 'Aangemaakt_op', 'Acties']" v-bind="$attrs">
    <template v-slot:colgroup>
      <colgroup>
        <col class="w-0" />
        <col class="w-0" />
        <col class="w-0" />
        <col class="w-0" />
        <col class="w-0" />
        <col class="w-auto text-right" />
      </colgroup>
    </template>
    <template v-slot:item-Promotie="{ item }">
      <button class="btn info">{{ item.code }}</button>
    </template>
    <template v-slot:item-Korting="{ item }">
      <div><b>Beschikbaar</b>: {{ formatPrice(item.korting_perc > 0 ? item.korting_perc : item.korting) }} {{ item.korting_perc > 0 ? '%' : 'EUR.' }}</div>
      <small v-if="item.korting_original > 0" class="italic">
        <b>Van totaal</b>: {{ formatPrice(item.korting_original) }} EUR.
      </small>
    </template>
    <template v-slot:item-Klant="{ item }">
      <div class="pr-4" :class="{ 'text-green-500': !item.email }">
        {{ item.email || 'Alle klanten' }}
      </div>
    </template>
    <template v-slot:item-Opmerking="{ item }">
      <div class="pr-4">
        <ButtonAlertOpmerking v-if="item.opmerking" :opmerking="item.opmerking" />
        <span v-else class="text-gray-600">/</span>
      </div>
    </template>
    <template v-slot:item-Tijd="{ item }">
      <div class="pr-4">
        <b>Start: </b>
        <span>{{ dateTimeStringLocale(item.start) }}</span>
      </div>
      <div>
        <b>Einde: </b>
        <span v-if="item.einde">{{ dateTimeStringLocale(item.einde) }}</span>
        <span v-else class="text-red-500">Geen einde</span>
      </div>
    </template>
    <template v-slot:item-Aangemaakt_op="{ item }">
      {{ dateTimeStringLocale(item.creation_date)}}
    </template>
    <template v-slot:item-Acties="{ item }">
      <button
        @click="onBewerken(item)"
        disabled
        class="btn warning text-white h-8"
      >
        <i class="fas fa-edit"></i> Bewerken
      </button>
    </template>
  </UITableResponsive>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import UITableResponsive from '@/components/UI/Table/Responsive'

import { dateTimeStringLocale } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'

const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))

defineProps({
  items: {
    type: Array,
    required: true,
  },
})

const onBewerken = (voucher) => {
  console.log({ voucher: voucher })
}
</script>
