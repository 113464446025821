<template>
  <div class="flex flex-col gap-6">
    <h2 class="text-xl font-semibold text-gray-900 -mb-2">
     Historiek raadplegen
    </h2>

    <div class="card flex flex-col gap-4 items-start">
      <UIInput v-model="apiData.code" class="w-full max-w-2xl" placeholder="Code ..." />
      <button :disabled="!apiData.code?.length" class="btn small text-xs bg-dashboard-dark text-white" @click="refreshData">Zoeken</button>
    </div>

    <div class="flex flex-col gap-4 justify-start" v-if="data.promotie">
      <div class="card">
        <h3 class="text-lg font-semibold text-gray-900 mb-2">
          Promotie
        </h3>
        <VouchersTable :items="[data.promotie]" hide-headers></VouchersTable>
      </div>

      <div class="card">
        <h3 class="text-lg font-semibold text-gray-900 mb-2">
          Historiek
        </h3>
        <VouchersTableHistoriek :items="data.historiek || []"></VouchersTableHistoriek>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useDebounceFn } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'
import useApi from '@/hooks/useApi'
import VouchersTable from '@/pages/administratie/vouchers/Table.vue'
import VouchersTableHistoriek from '@/pages/administratie/vouchers/TableHistoriek.vue'

const api = useApi()

const apiData = ref({
  code: '',
})

const data = ref({})
watch(() => apiData.value.code, () => { data.value = {} })

const refreshData = useDebounceFn(() => {
  api.get('VOUCHERS_HISTORIEK', apiData).then((res) => {
    data.value = res.data || {}
  })
}, 1000)
</script>
